<template>
  <SetupForm
    v-bind="$attrs"
    :title="$t('settings.automation.fivestars.optionalSettings.title')"
    :next-text="$t('settings.automation.fivestars.optionalSettings.nextText')"
    @submit="updateSettings"
    v-on="$listeners"
  >
    <RaiSetupToggle
      v-model="form.deductPoints"
      name="deductPoints"
      :title="
        $t('settings.automation.fivestars.optionalSettings.deductPoints.title')
      "
      :subtitle="
        $t(
          'settings.automation.fivestars.optionalSettings.deductPoints.subtitle'
        )
      "
    />
    <VxFormGroup
      :title="
        $t('settings.automation.fivestars.optionalSettings.autoTagsGroup.title')
      "
    >
      <p>
        {{
          $t(
            "settings.automation.fivestars.optionalSettings.autoTagsGroup.description"
          )
        }}
      </p>
      <v-row>
        <v-col cols="12" md="4">
          <VxTextField
            v-model="form.shopperTag"
            prefix="#"
            :placeholder="
              $t(
                'settings.automation.fivestars.optionalSettings.autoTagsGroup.shopperTag.placeholder'
              )
            "
            :label="
              $t(
                'settings.automation.fivestars.optionalSettings.autoTagsGroup.shopperTag.label'
              )
            "
            name="shopperTag"
          />
        </v-col>
        <v-col cols="12" md="4">
          <VxTextField
            v-model="form.sellerTag"
            prefix="#"
            :placeholder="
              $t(
                'settings.automation.fivestars.optionalSettings.autoTagsGroup.sellerTag.placeholder'
              )
            "
            :label="
              $t(
                'settings.automation.fivestars.optionalSettings.autoTagsGroup.sellerTag.label'
              )
            "
            name="sellerTag"
          />
        </v-col>
      </v-row>
    </VxFormGroup>
  </SetupForm>
</template>

<script>
import SetupForm from "../SetupForm.vue";
import { useFormObject } from "@/mixins/useFormObject";
import { RaiSetupToggle, VxFormGroup, VxTextField } from "@/core-ui";

export default {
  name: "OptionalSettingsSetup",
  components: {
    SetupForm,
    RaiSetupToggle,
    VxFormGroup,
    VxTextField,
  },
  mixins: [
    useFormObject({
      prop: "settings",
      default: {
        deductPoints: false,
        shopperTag: "",
        sellerTag: "",
      },
    }),
  ],
  methods: {
    updateSettings({ values, resolve }) {
      this.$emit("update:settings", values, resolve);
    },
  },
};
</script>
