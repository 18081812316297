// AWARD POINTS
export * from "./AwardPointsQuery.gql";
export * from "./AwardPointsUpdate.gql";

// OPTIONAL
export * from "./OptionalQuery.gql";
export * from "./OptionalUpdate.gql";

// AUTOMATION
export * from "./AutomationQuery.gql";
export * from "./AutomationUpdate.gql";
export * from "./AccountAdd.gql";
export * from "./AccountAddSubscription.gql";
