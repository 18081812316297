<template>
  <RaiSetups v-model="activeSetup" :loading="loading">
    <ConnectAccountSetup
      :automation="automation"
      :type="completedSetups[0]"
      :store-number="currentStoreNumber"
      @sync:account="onAccountSync"
      @next="onNext"
    />
    <AwardPointsSetup
      :disabled="!enableAutomationSetup"
      :type="completedSetups[1]"
      :award-points="awardPoints"
      @update:award-points="onAwardPointsUpdate"
      @next="onNext"
    />
    <OptionalSettingsSetup
      :disabled="!enableAutomationSetup"
      :type="completedSetups[2]"
      :settings="optionalSettings"
      @update:settings="onOptionalSettingsUpdate"
      @next="onNext"
    />
    <AutomationSetup
      :disabled="!enableAutomationSetup"
      :type="completedSetups[3]"
      :automation="automation"
      :award-points="awardPoints"
      @update:automation="onAutomationUpdate"
      @sync:account="onAccountSync"
      @next="onNext"
    />
    <TrainTeamSetup />
  </RaiSetups>
</template>

<script>
import { mapState } from "vuex";
import { useStoreView } from "./mixins/useStoreView";
import { RaiSetups, SetupType } from "@/core-ui";
import {
  ConnectAccountSetup,
  AwardPointsSetup,
  OptionalSettingsSetup,
  AutomationSetup,
  TrainTeamSetup,
} from "./components/Fivestars";
import { AutomationStatus } from "./constants";

import {
  FIVESTARS_AWARD_POINTS_QUERY,
  FIVESTARS_AWARD_POINTS_UPDATE,
  FIVESTARS_OPTIONAL_QUERY,
  FIVESTARS_OPTIONAL_UPDATE,
  FIVESTARS_AUTOMATION_QUERY,
  FIVESTARS_AUTOMATION_UPDATE,
  FIVESTARS_ADD_ACCOUNT,
  FIVESTARS_ACCOUNT_REQUEST_SENT_SUBSCRIPTION,
} from "./graphql/Fivestars";
import { subscribeOneOff } from "@/utils/graphql";
import { hasSnackbarAccess } from "@/mixins/snackbar";

export default {
  name: "FivestarsView",
  components: {
    ConnectAccountSetup,
    RaiSetups,
    AwardPointsSetup,
    OptionalSettingsSetup,
    AutomationSetup,
    TrainTeamSetup,
  },
  mixins: [hasSnackbarAccess, useStoreView()],
  data: () => ({
    activeSetup: 0,
    loading: false,
    awardPoints: {},
    completedSetups: new Array(5),
  }),
  apollo: {
    awardPoints: {
      query: FIVESTARS_AWARD_POINTS_QUERY,
      watchLoading(value) {
        this.setLoading(value);
      },
      variables() {
        return { storeId: this.storeId };
      },
      update(data) {
        return data.fivestarsAutomation;
      },
    },
    optionalSettings: {
      query: FIVESTARS_OPTIONAL_QUERY,
      watchLoading(value) {
        this.setLoading(value);
      },
      variables() {
        return { storeId: this.storeId };
      },
      update(data) {
        return data.fivestarsAutomation;
      },
    },
    automation: {
      query: FIVESTARS_AUTOMATION_QUERY,
      watchLoading(value) {
        this.setLoading(value);
      },
      variables() {
        return { storeId: this.storeId };
      },
      update(data) {
        return data.fivestarsAutomation;
      },
    },
    $subscribe: {
      fivestarAccountRequestSent: {
        query: FIVESTARS_ACCOUNT_REQUEST_SENT_SUBSCRIPTION,
        variables() {
          return {
            userId: this.userId,
            storeId: this.storeId,
          };
        },
        skip() {
          return !this.userId || !this.storeId;
        },
      },
    },
  },
  computed: {
    ...mapState("auth", { userId: "user_id" }),
    enableAutomationSetup() {
      return (
        this.automation &&
        (this.automation.status == AutomationStatus.ACTIVE ||
          this.automation.status == AutomationStatus.CONNECTED)
      );
    },
  },
  methods: {
    setLoading(value) {
      this.loading = value;
    },
    onNext() {
      this.completedSetups[this.activeSetup] = SetupType.COMPLETE;
      this.activeSetup++;
    },
    async onAwardPointsUpdate(values, resolve) {
      const { data } = await this.$apollo.mutate({
        mutation: FIVESTARS_AWARD_POINTS_UPDATE,
        variables: { storeId: this.storeId, input: values },
      });

      if (data.errors?.length > 0) {
        console.error("Error: FIVESTARS_AWARD_POINTS_UPDATE");
        return;
      }

      this.awardPoints = values;
      resolve();
    },
    async onOptionalSettingsUpdate(values, resolve) {
      const { data } = await this.$apollo.mutate({
        mutation: FIVESTARS_OPTIONAL_UPDATE,
        variables: { storeId: this.storeId, input: values },
      });

      if (data.errors?.length > 0) {
        console.error("Error: FIVESTARS_OPTIONAL_UPDATE");
        return;
      }

      this.optionalSettings = values;
      resolve();
    },
    async onAutomationUpdate(values, resolve) {
      const { data } = await this.$apollo.mutate({
        mutation: FIVESTARS_AUTOMATION_UPDATE,
        variables: {
          storeId: this.storeId,
          input: values,
        },
      });

      if (data.errors?.length > 0) {
        console.error("Error: FIVESTARS_AUTOMATION_UPDATE");
        return;
      }

      this.automation = data.fivestarsAutomationUpdate.automation;
      resolve();
    },
    async onAccountSync(context) {
      this.subscribeToAccountSync(context);

      const { data } = await this.$apollo.mutate({
        mutation: FIVESTARS_ADD_ACCOUNT,
        variables: {
          storeId: this.storeId,
        },
      });

      if (data.fivestarsAddAccount.errors?.length > 0) {
        // This for now does not return errors.
        console.error("Error: FIVESTARS_ACCOUNT_ADD");
        return;
      }
    },
    subscribeToAccountSync(context) {
      subscribeOneOff(this, {
        name: "fivestarAccountRequestSent",
        resolve: (result) => {
          this.showSnackbar({
            text: result.success
              ? this.$t("settings.automation.fivestars.accountAddedSuccessText")
              : this.$t("settings.automation.fivestars.accountAddedErrorText", {
                  error: result.errors.join(", "),
                }),
          });

          context.resolve();
        },
      });
    },
  },
};
</script>
