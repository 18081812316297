<template>
  <SetupForm
    v-bind="$attrs"
    :title="$t('settings.automation.fivestars.automation.title')"
    :next-text="$t('settings.automation.fivestars.automation.nextText')"
    @submit="updateAutomation"
    v-on="$listeners"
  >
    <RaiSetupToggle
      v-model="form.enableAutomation"
      name="enableAutomation"
      :disabled="!canEnableAutomation"
      :title="$t('settings.automation.fivestars.automation.enableToggle.title')"
    >
      <template #subtitle>
        {{
          $t("settings.automation.fivestars.automation.enableToggle.subtitle")
        }}
      </template>
    </RaiSetupToggle>
    <v-row>
      <v-col cols="12" md="6" lg="4">
        <VxDatePicker
          v-model="form.startDate"
          :label="
            $t('settings.automation.fivestars.automation.startingDate.label')
          "
          :placeholder="
            $t(
              'settings.automation.fivestars.automation.startingDate.placeholder'
            )
          "
          name="startDate"
          :min="startDateMin"
          :disabled="!canEnableAutomation"
          rules="required"
        />
      </v-col>
    </v-row>
    <VxAlert v-if="hasAwardPointsDefined" type="warning">
      <i18n path="settings.automation.fivestars.automation.enableAlert.text">
        <template #prefix>
          <strong>
            {{
              $t("settings.automation.fivestars.automation.enableAlert.prefix")
            }}
          </strong>
        </template>
      </i18n>
    </VxAlert>
    <VxAlert v-else type="warning">
      <i18n
        path="settings.automation.fivestars.automation.noAwardPointsAlert.text"
      >
        <template #prefix>
          <strong>
            {{
              $t(
                "settings.automation.fivestars.automation.noAwardPointsAlert.prefix"
              )
            }}
          </strong>
        </template>
      </i18n>
    </VxAlert>
  </SetupForm>
</template>

<script>
import SetupForm from "../SetupForm.vue";
import { useFormObject } from "@/mixins/useFormObject";
import { AutomationStatus } from "../../constants";
import { RaiSetupToggle, VxAlert, VxDatePicker } from "@/core-ui";

export default {
  name: "AutomationSetup",
  components: {
    SetupForm,
    RaiSetupToggle,
    VxAlert,
    VxDatePicker,
  },
  mixins: [
    useFormObject({
      prop: "automation",
      default: {
        startDate: null,
        enableAutomation: null,
        status: AutomationStatus.NOT_CONNECTED,
      },
    }),
  ],
  props: {
    awardPoints: {
      type: Object,
      default: () => ({
        buyTransactions: null,
        saleTransactions: null,
      }),
    },
  },
  data: () => ({
    syncing: false,
  }),
  computed: {
    canEnableAutomation() {
      return this.isConnected && this.hasAwardPointsDefined;
    },
    hasAwardPointsDefined() {
      return (
        !!this.awardPoints?.buyTransactions ||
        !!this.awardPoints?.saleTransactions
      );
    },
    isConnected() {
      return (
        this.form.status === AutomationStatus.CONNECTED ||
        this.form.status === AutomationStatus.ACTIVE
      );
    },
    startDateMin() {
      const today = new Date();
      today.setDate(today.getDate() + 1);
      return today;
    },
  },
  methods: {
    updateAutomation({ values, resolve }) {
      this.$emit("update:automation", values, resolve);
    },
  },
};
</script>
