var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SetupForm',_vm._g(_vm._b({attrs:{"title":_vm.$t('settings.automation.fivestars.awardPoints.title'),"next-text":_vm.$t('settings.automation.fivestars.awardPoints.nextText')},on:{"submit":_vm.updateAwardPoints},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("settings.automation.fivestars.awardPoints.extensionText"))+" ")]},proxy:true}])},'SetupForm',_vm.$attrs,false),_vm.$listeners),[_c('VxFormGroup',{attrs:{"title":_vm.$t(
        'settings.automation.fivestars.awardPoints.buyTransactionGroup.title'
      )}},[_c('i18n',{attrs:{"path":"settings.automation.fivestars.awardPoints.buyTransactionGroup.conditionText"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('VxTextField',{staticClass:"d-inline-block mx-2",staticStyle:{"width":"90px"},attrs:{"label":_vm.$t(
              'settings.automation.fivestars.awardPoints.buyTransactionGroup.inputLabel'
            ),"type":"number","name":"buyTransactions","rules":"required_if:saleTransactions,null,|numeric|min_value:0","prefix":"$"},model:{value:(_vm.form.buyTransactions),callback:function ($$v) {_vm.$set(_vm.form, "buyTransactions", _vm._n($$v))},expression:"form.buyTransactions"}})]},proxy:true}])})],1),_c('VxFormGroup',{attrs:{"title":_vm.$t(
        'settings.automation.fivestars.awardPoints.salesTransactionGroup.title'
      )}},[_c('i18n',{attrs:{"path":"settings.automation.fivestars.awardPoints.salesTransactionGroup.conditionText"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('VxTextField',{staticClass:"d-inline-block mx-2",staticStyle:{"width":"90px"},attrs:{"label":_vm.$t(
              'settings.automation.fivestars.awardPoints.salesTransactionGroup.inputLabel'
            ),"type":"number","name":"saleTransactions","rules":"required_if:buyTransactions,null,|numeric|min_value:0","prefix":"$"},model:{value:(_vm.form.saleTransactions),callback:function ($$v) {_vm.$set(_vm.form, "saleTransactions", _vm._n($$v))},expression:"form.saleTransactions"}})]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }