<template>
  <TextWithLinksStep
    :title="$t('settings.automation.fivestars.trainTeam.title')"
    :sections="$t('settings.automation.fivestars.trainTeam.sections')"
  >
    <template #extension>
      {{ $t("settings.automation.fivestars.trainTeam.extensionText") }}
    </template>
  </TextWithLinksStep>
</template>

<script>
import TextWithLinksStep from "@/views/Common/TextWithLinksStep.vue";

export default {
  name: "TrainTeamSetup",
  components: {
    TextWithLinksStep,
  },
};
</script>
