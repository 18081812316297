<template>
  <SetupForm
    v-bind="$attrs"
    :title="$t('settings.automation.fivestars.awardPoints.title')"
    :next-text="$t('settings.automation.fivestars.awardPoints.nextText')"
    @submit="updateAwardPoints"
    v-on="$listeners"
  >
    <template #extension>
      {{ $t("settings.automation.fivestars.awardPoints.extensionText") }}
    </template>
    <VxFormGroup
      :title="
        $t(
          'settings.automation.fivestars.awardPoints.buyTransactionGroup.title'
        )
      "
    >
      <i18n
        path="settings.automation.fivestars.awardPoints.buyTransactionGroup.conditionText"
      >
        <template #input>
          <VxTextField
            v-model.number="form.buyTransactions"
            :label="
              $t(
                'settings.automation.fivestars.awardPoints.buyTransactionGroup.inputLabel'
              )
            "
            type="number"
            name="buyTransactions"
            class="d-inline-block mx-2"
            style="width: 90px"
            rules="required_if:saleTransactions,null,|numeric|min_value:0"
            prefix="$"
          />
        </template>
      </i18n>
    </VxFormGroup>
    <VxFormGroup
      :title="
        $t(
          'settings.automation.fivestars.awardPoints.salesTransactionGroup.title'
        )
      "
    >
      <i18n
        path="settings.automation.fivestars.awardPoints.salesTransactionGroup.conditionText"
      >
        <template #input>
          <VxTextField
            v-model.number="form.saleTransactions"
            :label="
              $t(
                'settings.automation.fivestars.awardPoints.salesTransactionGroup.inputLabel'
              )
            "
            type="number"
            name="saleTransactions"
            class="d-inline-block mx-2"
            style="width: 90px"
            rules="required_if:buyTransactions,null,|numeric|min_value:0"
            prefix="$"
          />
        </template>
      </i18n>
    </VxFormGroup>
  </SetupForm>
</template>

<script>
import SetupForm from "../SetupForm.vue";
import { useFormObject } from "@/mixins/useFormObject";
import { VxFormGroup, VxTextField } from "@/core-ui";

export default {
  name: "AwardPointsSetup",
  components: {
    SetupForm,
    VxFormGroup,
    VxTextField,
  },
  mixins: [
    useFormObject({
      prop: "awardPoints",
      default: {
        buyTransactions: null,
        saleTransactions: null,
      },
    }),
  ],
  methods: {
    updateAwardPoints({ values, resolve }) {
      this.$emit("update:award-points", values, resolve);
    },
  },
};
</script>
