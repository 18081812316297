var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SetupForm',_vm._g(_vm._b({attrs:{"title":_vm.$t('settings.automation.fivestars.optionalSettings.title'),"next-text":_vm.$t('settings.automation.fivestars.optionalSettings.nextText')},on:{"submit":_vm.updateSettings}},'SetupForm',_vm.$attrs,false),_vm.$listeners),[_c('RaiSetupToggle',{attrs:{"name":"deductPoints","title":_vm.$t('settings.automation.fivestars.optionalSettings.deductPoints.title'),"subtitle":_vm.$t(
        'settings.automation.fivestars.optionalSettings.deductPoints.subtitle'
      )},model:{value:(_vm.form.deductPoints),callback:function ($$v) {_vm.$set(_vm.form, "deductPoints", $$v)},expression:"form.deductPoints"}}),_c('VxFormGroup',{attrs:{"title":_vm.$t('settings.automation.fivestars.optionalSettings.autoTagsGroup.title')}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t( "settings.automation.fivestars.optionalSettings.autoTagsGroup.description" ))+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('VxTextField',{attrs:{"prefix":"#","placeholder":_vm.$t(
              'settings.automation.fivestars.optionalSettings.autoTagsGroup.shopperTag.placeholder'
            ),"label":_vm.$t(
              'settings.automation.fivestars.optionalSettings.autoTagsGroup.shopperTag.label'
            ),"name":"shopperTag"},model:{value:(_vm.form.shopperTag),callback:function ($$v) {_vm.$set(_vm.form, "shopperTag", $$v)},expression:"form.shopperTag"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('VxTextField',{attrs:{"prefix":"#","placeholder":_vm.$t(
              'settings.automation.fivestars.optionalSettings.autoTagsGroup.sellerTag.placeholder'
            ),"label":_vm.$t(
              'settings.automation.fivestars.optionalSettings.autoTagsGroup.sellerTag.label'
            ),"name":"sellerTag"},model:{value:(_vm.form.sellerTag),callback:function ($$v) {_vm.$set(_vm.form, "sellerTag", $$v)},expression:"form.sellerTag"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }