<template>
  <SetupForm
    v-bind="$attrs"
    :title="$t('settings.automation.fivestars.connectAccount.title')"
    :next-text="$t('settings.automation.fivestars.connectAccount.nextText')"
    :next-disabled="!enableNext"
    @submit="gotoNext"
    v-on="$listeners"
  >
    <VxFormGroup title="Account status">
      <p v-if="isConnected">
        <v-icon color="success" left>$success</v-icon>
        {{ isConnectedText }}
      </p>
      <template v-else>
        <VxBtn :loading="syncing" class="mb-6" @click="syncAccount">
          <v-icon left>$sync</v-icon>
          {{ $t("settings.automation.fivestars.connectAccount.syncBtnText") }}
        </VxBtn>
        <VxAlert type="warning">
          {{ isNotConnectedText }}
        </VxAlert>
        <VxAlert type="info">
          <i18n
            path="settings.automation.fivestars.connectAccount.syncAlert.text"
          >
            <template #prefix>
              <strong>
                {{
                  $t(
                    "settings.automation.fivestars.connectAccount.syncAlert.prefix"
                  )
                }}
              </strong>
            </template>
            <template #link>
              <RaiLink :href="emailLink">
                {{ $t("global.supportEmail") }}
              </RaiLink>
            </template>
          </i18n>
        </VxAlert>
      </template>
    </VxFormGroup>
  </SetupForm>
</template>

<script>
import SetupForm from "../SetupForm.vue";
import { useFormObject } from "@/mixins/useFormObject";
import { AutomationStatus } from "../../constants";
import { VxFormGroup, VxAlert, VxBtn, RaiLink } from "@/core-ui";

export default {
  name: "AutomationSetup",
  components: {
    SetupForm,
    VxFormGroup,
    VxAlert,
    VxBtn,
    RaiLink,
  },
  mixins: [
    useFormObject({
      prop: "automation",
      default: {
        startDate: null,
        enableAutomation: null,
        status: AutomationStatus.NOT_CONNECTED,
      },
    }),
  ],
  props: {
    storeNumber: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    syncing: false,
  }),
  computed: {
    emailLink() {
      return `mailto:${this.$t("global.supportEmail")}`;
    },
    isConnected() {
      return (
        this.form.status === AutomationStatus.CONNECTED ||
        this.form.status === AutomationStatus.ACTIVE
      );
    },
    isConnectedText() {
      return this.$t(
        "settings.automation.fivestars.connectAccount.connectedText",
        { storeNumber: this.storeNumber }
      );
    },
    isNotConnectedText() {
      return this.$t(
        "settings.automation.fivestars.connectAccount.notConnectedText",
        { storeNumber: this.storeNumber }
      );
    },
    enableNext() {
      return (
        this.automation &&
        (this.automation.status == AutomationStatus.ACTIVE ||
          this.automation.status == AutomationStatus.CONNECTED)
      );
    },
  },
  methods: {
    gotoNext({ values, resolve }) {
      this.$emit("goto:next", values, resolve);
      resolve();
    },
    syncAccount() {
      this.syncing = true;
      this.$emit("sync:account", {
        resolve: () => {
          this.syncing = false;
        },
      });
    },
  },
};
</script>
